import React, {Dispatch, SetStateAction, useEffect} from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useAppSelector} from "../../../../../hooks/redux";
import {IOneDashboardElement} from "../../../../../types/redux_types";


export interface IDashboardSelectElementProps{
    element: IOneDashboardElement | undefined,
    setElement: Dispatch<SetStateAction<IOneDashboardElement | undefined>>,
    symbol?: string
}

const DashboardSelectElement: React.FC<IDashboardSelectElementProps> = ({element, setElement, symbol}) => {
    const dashboardElements = useAppSelector(state => state.dashboardElements.dataArray)
    const [localElementId, setLocalElementId] = React.useState<string>('');

    const [localDashboardElements, setLocalDashboardElements] = React.useState<IOneDashboardElement[]>(dashboardElements);

    const handleSelectElement = (event: SelectChangeEvent) => {
        setLocalElementId(event.target.value as string);
        setElement(dashboardElements.find(el => el.id.toString() === event.target.value.toString()))
    };

    useEffect(() => {
        setLocalDashboardElements(dashboardElements.filter((el) => (el.for_all && symbol === 'ALL') || (symbol !== 'ALL')))
    },[])
    useEffect(() => {
        setLocalDashboardElements(dashboardElements.filter((el) => (el.for_all && symbol === 'ALL') || (symbol !== 'ALL')))
    },[symbol])



    return (
        <>
            <div style={{margin: "16px"}}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select element type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={localElementId}
                        value={element === undefined ? "" : element.id.toString()}
                        label="Select element type"
                        onChange={handleSelectElement}
                    >
                        {localDashboardElements.map((element) => (
                            <MenuItem
                                key={element.name}
                                value={element.id.toString()}
                            >
                                {element.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </>
    )
}

export default DashboardSelectElement;