import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {IOneDashboardCardProps} from "../../../../types/component_types";
import {useAppSelector} from "../../../../hooks/redux";
import Grid from '@mui/material/Grid';
import DashboardCardDialog from "../DashboardCardDialog/DashboardCardDialog";
import {useEffect, useState} from "react";
import {getAllAvaliableSymbols} from "../../../../asyncActions/userSettings/getAllAvaliableSymbols";
import {getDashboardGetOneUserPointData} from "../../../../asyncActions/dashboard/getDashboardGetOneUserPointData";
import {IOneDashboardUserPointData} from "../../../../types/redux_types";

const DashboardCardWithData: React.FC<IOneDashboardCardProps> = ({positionId, elementId, symbol, days}) => {
    const dashboardElements = useAppSelector(state => state.dashboardElements.dataArray)
    const elementName = dashboardElements.find((el) => el.id === elementId)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [onePointData, setOnePointData] = useState<IOneDashboardUserPointData | undefined>()

    const cardData : IOneDashboardCardProps ={
        positionId: positionId,
        elementId: elementId,
        symbol: symbol,
        days: days
    }

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            let localElementId: number = 0
            if (elementId !== undefined){localElementId = elementId}
            let localSymbol: string = ""
            if (symbol !== undefined){localSymbol = symbol}
            let localDays: number = 0
            if (days !== undefined){localDays = days}

            const data = await getDashboardGetOneUserPointData(localElementId, localSymbol, localDays);
            setOnePointData(data)
            // return data;
        }
        fetchData()
    },[days, symbol, elementId]);

    return (
        <>
            <DashboardCardDialog open={openDialog} setOpen={setOpenDialog} cardData={cardData} />
            <Card sx={{minWidth: 275, minHeight: 200}} onClick={handleClickOpenDialog}>
                <CardContent>
                    <div style={{margin: "16px"}}>
                        <Grid container spacing={0}  >
                            <Grid item xs={5} sm={6} md={5}>
                                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>symbol: {symbol}</Typography>
                            </Grid>
                            <Grid item xs={4} sm={6} md={4}>
                                {elementName !== undefined && <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>{elementName.name}</Typography>}
                            </Grid>
                            <Grid item xs={3} sm={6} md={3}>
                                {elementName !== undefined && elementName.days_avaliable && <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>days: {days}</Typography>}
                            </Grid>
                        </Grid>
                    </div>
                    <Typography sx={{ml: 3, mr: 'auto'}} variant="h5" component="div">
                        {onePointData !== undefined && onePointData.first_string}
                    </Typography>
                    {/*<Typography sx={{mb: 1.5}} color="text.secondary">adjective</Typography>*/}
                    <Typography sx={{fontSize: 12, mx: 5}} color="text.secondary" gutterBottom>{onePointData !== undefined && onePointData.date_begin}</Typography>
                    <Typography sx={{fontSize: 12, mx: 5}} color="text.secondary" gutterBottom>{onePointData !== undefined && onePointData.date_end}</Typography>
                </CardContent>
                {/*<CardActions>*/}
                {/*    <Button size="small">Learn More</Button>*/}
                {/*</CardActions>*/}
            </Card>
        </>
    )
}

export default DashboardCardWithData;