import React, {useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {Paper, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useAppDispatch} from "../../hooks/redux";
import {useNavigate} from "react-router-dom";
import CodeInput from "../../components/Utils/CodeInput/CodeInput";
import {genCode} from "../../asyncActions/user/genCode";
import {loginUserByMailAsync} from "../../asyncActions/user/mail_login_user";
import {loginUserByTelegramAsync} from "../../asyncActions/user/telegram_login_user";


const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [step, setStep] = useState<number>(0);


    const handleEmail = (e: React.ChangeEvent<any>) => {
        if (e.target.value !== undefined){setEmail(e.target.value)}
    }

    const handlePassword = (e: React.ChangeEvent<any>) => {
        if (e.target.value !== undefined){setPassword(e.target.value)}
    }

    const handleLoginOnClick = () => {
        if(isValidEmail(email)){
            dispatch(loginUserByMailAsync(email, password))
        }else{
            dispatch(loginUserByTelegramAsync(email, password))
        }
        setTimeout(() => {
            navigate("/");
        }, 2000);
    }

    const handleSendCode = () => {
        setStep(1)
        genCode(email)
    }

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }
    return(
        <>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '85vh',
                    alignItems: "center",
                    justifyContent: 'center',
                }}
            >
                <Box
                    maxWidth="80vw"
                    minWidth="60vw"
                >
                    <Paper />
                    <div style={{margin: "16px"}}>
                        {(step === 0) && <Typography variant="h4" component="h4">
                            Введите почту или аккаунт телеграмма
                        </Typography>}
                        {(step === 1) && <Typography variant="h4" component="h4">
                            Введите пароль для {email}
                        </Typography>}

                        {(step === 0) && <div style={{marginTop: "16px"}}>
                            <TextField
                                fullWidth
                                required
                                value={email}
                                label="Email/Telegram"
                                onChange={handleEmail}
                            />
                        </div>}

                        {(step === 0) && <div style={{marginTop: "16px"}}>
                            <Button
                                variant="contained"
                                onClick={handleSendCode}
                                // disabled={ !isValidEmail(email) || password.length === 0 }
                            >Send code</Button>
                        </div>}


                        {(step === 1) && <div style={{marginTop: "16px"}}>
                            <CodeInput separator={<span>-</span>} value={password} onChange={setPassword} length={6} />
                            <span>Entered value: {password}</span>
                        </div>}

                        {(step === 1) && <div style={{marginTop: "16px"}}>
                            <Button
                                variant="contained"
                                onClick={handleLoginOnClick}
                                // disabled={ !isValidEmail(email) || password.length === 0 }
                            >Login</Button>
                        </div>}


                    </div>
                </Box>
            </Box>
        </>
    )
}

export default LoginPage;