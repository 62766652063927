import {IUserLicense} from "../types/redux_types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: IUserLicense = {
    id: 0,
    name: "",
    symbols_count: 0,
    tasks_count: 0,
    max_tasks_length: 0,
    trade_units_count: 0,
    cost_per_1_month: 0,
    cost_per_6_month: 0,
    cost_per_12_month: 0,
    monthly_profit_limit: 0
}

export const userLicenseSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reduxLicenseLogin (state, action: PayloadAction<IUserLicense>){
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.symbols_count = action.payload.symbols_count;
            state.tasks_count = action.payload.tasks_count;
            state.max_tasks_length = action.payload.max_tasks_length;
            state.trade_units_count = action.payload.trade_units_count;
            state.cost_per_1_month = action.payload.cost_per_1_month;
            state.cost_per_6_month = action.payload.cost_per_6_month;
            state.cost_per_12_month = action.payload.cost_per_12_month;
            state.monthly_profit_limit = action.payload.monthly_profit_limit;
        },
        reduxLicenseLogout(state){
            state.id = 0;
            state.name = "";
            state.symbols_count = 0;
            state.tasks_count = 0;
            state.max_tasks_length = 0;
            state.trade_units_count = 0;
            state.cost_per_1_month = 0;
            state.cost_per_6_month = 0;
            state.cost_per_12_month = 0;
            state.monthly_profit_limit = 0;
        }
    }
})

export default userLicenseSlice.reducer;