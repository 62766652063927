import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import {Route, Routes, Navigate} from "react-router-dom";
import IndexPage from "../../pages/IndexPage/IndexPage";
import RegisterPage from "../../pages/RegisterPage/RegisterPage";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
import UserProfileSettingsPage from "../../pages/UserProfileSettingsPage/UserProfileSettingsPage";
import DashboardPage from "../../pages/DashboardPage/DashboardPage";
import LoginPage from "../../pages/LoginPage/LoginPage";
import VTUPage from "../../pages/VTUPage/VTUPage";

const MainArea: React.FC = () => {
    return(
        <>
            <CssBaseline />
            <div>
                <Routes>
                    <Route path='/' element={ <IndexPage /> }/>
                    <Route path='/register' element={ <RegisterPage />}/>
                    <Route path='/login' element={ <LoginPage />}/>
                    <Route path='/user_profile_settings' element={ <UserProfileSettingsPage />}/>
                    <Route path='/dashboard' element={ <DashboardPage />}/>
                    <Route path='/vtu' element={ <VTUPage />}/>
                    <Route path='/404' element={ <NotFoundPage />}/>
                    <Route path="*" element={ <Navigate to="/404" />}/>
                </Routes>
            </div>
        </>
    )
}

export default MainArea;