import React, {useEffect} from 'react'
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LoginMenuComponent from "./LoginMenuComponent/LoginMenuComponent";
import UserAvatarMenuComponent from "./UserAvatarMenuComponent/UserAvatarMenuComponent";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getUser} from "../../asyncActions/user/get_user";
import {useNavigate, NavLink} from "react-router-dom";
import {getUserSymbols} from "../../asyncActions/userSettings/getUserSymbols";
import {getUserLicense} from "../../asyncActions/user/getUserLicense";
import Grid from '@mui/material/Grid';


const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
        minHeight: 128,
    },
}));

const DesktopComponent: React.FC = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useAppDispatch()
    const user = useAppSelector(state => state.user)

    const userLicense = useAppSelector(state => state.userLicense)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleRegister = () => {
        navigate("/register")
    }


    useEffect(() => {
        dispatch(getUser())
        dispatch(getUserLicense())
        dispatch(getUserSymbols())
    },[]);

    const menuStyle = {
        display: "flex",
        justifyContent: (user.email===undefined || user.email==="") ? "center" : "flex-end",
        alignItems: "center"
    };

    return(
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}  >
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography
                                    variant="h6"
                                    // component="div"
                                    noWrap
                                    component={NavLink}
                                    to="/"
                                    sx={{ flexGrow: 1 }}
                                >
                                    Binance Trade Helper
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}  >
                                {!(userLicense.name===undefined || userLicense.name==="") && <p>License: {userLicense.name}  </p>}
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}  style={{display: "flex", alignItems: "center"}}>
                                {(user.email===undefined || user.email==="") && <Button component={NavLink} to="/login" variant="text" color="inherit" >Login</Button>}
                                {!(user.email===undefined || user.email==="") && <p>Email: {user.email}</p>}
                            </Grid>
                            <Grid
                                item
                                xs={3} sm={3} md={3} lg={3} xl={3}
                                style={menuStyle}
                            >
                                {(user.email===undefined || user.email==="") && <Button component={NavLink} to="/register" variant="text" color="inherit" >Register</Button>}
                                {!(user.email===undefined || user.email==="") && <UserAvatarMenuComponent />}
                                {!(user.email===undefined || user.email==="") &&  <LoginMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}

                                {/*{(user.email===undefined || user.email==="") && <Button*/}
                                {/*    id="fade-button"*/}
                                {/*    aria-controls={open ? 'fade-menu' : undefined}*/}
                                {/*    aria-haspopup="true"*/}
                                {/*    aria-expanded={open ? 'true' : undefined}*/}
                                {/*    color="inherit"*/}
                                {/*    onClick={handleClick}>Login</Button>}*/}
                            </Grid>
                            {/*<Grid item xs={2} sm={2} md={2} lg={2} xl={2}  >*/}
                            {/*    {!(user.email===undefined || user.email==="") && <UserAvatarMenuComponent />}*/}
                            {/*    <LoginMenuComponent anchorEl={anchorEl} setAnchorEl={setAnchorEl} />*/}
                            {/*</Grid>*/}

                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}  >
                                <Button component={NavLink} to="/dashboard" variant="text" color="inherit" >DASHBOARD</Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}  >
                                <Button component={NavLink} to="/vtu" variant="text" color="inherit" >VTU</Button>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}  >
                                <Button component={NavLink} to="/vtu" variant="text" color="inherit" >TASKS</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    )
}

export default DesktopComponent;