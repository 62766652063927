import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {yellow, teal, grey} from '@mui/material/colors';
import {Provider} from 'react-redux';
import {setupStore} from "./redux/store";

const outerTheme = createTheme({
    palette: {
        primary: yellow,
        secondary: grey,
    },
});

const store = setupStore()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store = {store}>
        <React.StrictMode>
          <ThemeProvider theme={outerTheme}>
            <App />
          </ThemeProvider>
        </React.StrictMode>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
