import {IUserSymbolsState} from "../types/redux_types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const initialState: IUserSymbolsState = {
    symbolsArray: []
}


export const userSymbolsSlice = createSlice({
    name: 'userSymbolsState',
    initialState,
    reducers: {
        updateSymbolsArray (state, action: PayloadAction<string[]>){
            state.symbolsArray = action.payload;
        }
    }
})

export default userSymbolsSlice.reducer;