import {IOneDashboardElement, IOneDashboardElementState} from "../types/redux_types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const initialState: IOneDashboardElementState = {
    dataArray: []
}


export const dashboardElementsSlice = createSlice({
    name: 'dashboardElementsState',
    initialState,
    reducers: {
        updateDashboardElementsArray (state, action: PayloadAction<IOneDashboardElement[]>){
            state.dataArray = action.payload;
        }
    }
})

export default dashboardElementsSlice.reducer;