import {userLicenseSlice} from "../../redux/userLicenseReducer";
import {AppDispatch} from "../../redux/store";
import {siteOrigin, backendUrl} from "../../globalConfig";



export const getUserLicense = () => async (dispatch: AppDispatch) => {
    fetch(backendUrl+'/license/get_user_license', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then((r) =>  {
            if(r.status===200){
                return r.json().then(data => ({status: r.status, json: data}))
            } else {
                let error = new Error(r.statusText);
                throw error
            }
        })
        .then((data) => {
            if (data.status===200){
                dispatch(userLicenseSlice.actions.reduxLicenseLogin(data.json))
            } else {
                dispatch(userLicenseSlice.actions.reduxLicenseLogout())
            }
        })
        .catch((e) => {
            console.log('Error: ' + e.message);
        })
}