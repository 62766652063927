import * as React from 'react';
import Card from '@mui/material/Card';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {IconButton} from "@mui/material";
import {IOneDashboardCardProps} from "../../../../types/component_types";
import DashboardCardDialog from "../DashboardCardDialog/DashboardCardDialog";

const DashboardCardEmpty: React.FC<IOneDashboardCardProps>  = ({positionId}) => {
    const [open, setOpen] = React.useState(false);
    const cardData : IOneDashboardCardProps ={
        positionId: positionId
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    return (
        <>
            <DashboardCardDialog open={open} setOpen={setOpen} cardData={cardData} />
            <Card sx={{minWidth: 275, minHeight: 180, alignItems: "center" }}>
                <div style={{ display:'flex', justifyContent:'center', alignItems: 'center'}} >
                    <IconButton
                        aria-label="delete"
                        size="large"
                        onClick={handleClickOpen}
                        sx={{ minWidth: 180, minHeight: 180, justifyContent: "center" }}
                    >
                        <AddCircleOutlineIcon sx={{ minWidth: 180, minHeight: 180, justifyContent: "center" }} />
                    </IconButton>
                </div>
            </Card>
        </>
    )
}

export default DashboardCardEmpty;