import React, {useState} from "react";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import {loginUserAsync} from "../../../asyncActions/user/login_user";
import {useAppDispatch} from "../../../hooks/redux";
import { useNavigate } from "react-router-dom";

export interface ILoginMenuComponent{
    anchorEl: null | HTMLElement,
    setAnchorEl: any,
}

const LoginMenuComponent: React.FC<ILoginMenuComponent> = ({anchorEl, setAnchorEl}) => {
    const open = Boolean(anchorEl);
    const [loginLogin, setLoginLogin] = useState<string>("");
    const [loginPasswod, setLoginPassword] = useState<string>("");
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const handleLoginLogin = (e: React.ChangeEvent<any>) => {
        if (e.target.value !== undefined){setLoginLogin(e.target.value)}
    }

    const handleLoginPassword = (e: React.ChangeEvent<any>) => {
        if (e.target.value !== undefined){setLoginPassword(e.target.value)}
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLoginOnClick = () => {
        dispatch(loginUserAsync(loginLogin, loginPasswod))
        handleClose()
        setTimeout(() => {
            navigate("/");
        }, 1000);
    }

    return(
        <>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <Box component="span"
                     sx={{
                         width: 250,
                         height: 120,
                     }}
                >
                    <div style={{margin: "16px"}}>
                        <div style={{marginTop: "16px"}}>
                            <TextField
                                fullWidth
                                value={loginLogin}
                                label="Username"
                                onChange={handleLoginLogin}
                            />
                        </div>
                        <div style={{marginTop: "16px"}}>
                            <TextField
                                fullWidth
                                value={loginPasswod}
                                label="Password"
                                type="password"
                                onChange={handleLoginPassword}
                            />
                        </div>
                        <div style={{marginTop: "16px"}}>
                            <Button variant="contained" onClick={handleLoginOnClick}>Sign In</Button>
                        </div>
                    </div>
                </Box>
            </Menu>
        </>
    )
}
export default LoginMenuComponent;