import {backendUrl} from "../../globalConfig";
import {userSymbolsSlice} from "../../redux/userSymbolsReducer";
import {AppDispatch} from "../../redux/store";

export const updateUserSymbols = (symbols: string[]) => async (dispatch: AppDispatch) =>  {
    fetch(backendUrl+'/user/user_symbol_update_many', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(symbols)
    })
        .then((r) =>  {
            if(r.status===200){
                return r.json().then(data => ({status: r.status, json: data}))
            } else {
                let error = new Error(r.statusText);
                throw error
            }
        })
        .then((data) => {
            if (data.status===200){
                dispatch(userSymbolsSlice.actions.updateSymbolsArray(data.json))
            }
        })
        .catch((e) => {
            console.log('Error: ' + e.message);
        })
}