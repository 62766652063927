import {ILoginUser} from "../types/redux_types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: ILoginUser = {
    id: "",
    email: "",
    avatar_path: "no_avatar",
    api_key: "",
    api_secret_key: "",
    telegram_username: ""
}

export const LoginSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        redux_login (state, action: PayloadAction<ILoginUser>){
            state.id = action.payload.id;
            state.email = action.payload.email;
            state.avatar_path = action.payload.avatar_path;
            state.api_key = action.payload.api_key;
            state.api_secret_key = action.payload.api_secret_key;
            state.telegram_username = action.payload.telegram_username;
        },
        redux_logout(state){
            state.id = "";
            state.email = "";
            state.avatar_path = "";
            state.api_key = "";
            state.api_secret_key = "";
            state.telegram_username = "";
        }
    }
})

export default LoginSlice.reducer;