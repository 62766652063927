import React, {useEffect, useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {Box, Paper, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {updateUserSettings} from "../../asyncActions/userSettings/setUserSettings";
import UserProfileSelectSymbols from "./UserProfileSelectSymbols/UserProfileSelectSymbols";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {updateUserSymbols} from "../../asyncActions/userSettings/updateUserSymbols";
import {getUser} from "../../asyncActions/user/get_user";
import {getUserLicense} from "../../asyncActions/user/getUserLicense";
import {getUserSymbols} from "../../asyncActions/userSettings/getUserSymbols";

const UserProfileSettingsPage: React.FC = () => {
    const dispatch = useAppDispatch()
    const userSymbols = useAppSelector(state => state.userSymbols.symbolsArray)
    const user = useAppSelector(state => state.user)
    const userLicense = useAppSelector(state => state.userLicense)

    const [APIKey, setAPIKey] = useState<string>("");
    const [APISecretKey, setAPISecretKey] = useState<string>("");
    const [telegramUsername, setTelegramUsername] = useState<string>("");
    const [symbolsArray, setSymbolsArray] = useState<string[]>([]);

    const symbolsCountLeft = userLicense.symbols_count - symbolsArray.length

    const handleAPIKey = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setAPIKey(e.target.value)}
    }
    const handleAPISecretKey = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setAPISecretKey(e.target.value)}
    }
    const handleTelegramUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value !== undefined){setTelegramUsername(e.target.value)}
    }

    const handleSave = () => {
        updateUserSettings(APIKey, APISecretKey, telegramUsername)
        dispatch(updateUserSymbols(symbolsArray))
    }


    useEffect(() => {
        dispatch(getUser())
        dispatch(getUserLicense())
        dispatch(getUserSymbols())
        setSymbolsArray(userSymbols)
    },[]);

    useEffect(() => {
        setSymbolsArray(userSymbols)
    },[userSymbols]);

    useEffect(() => {
        setAPIKey(user.api_key)
        setAPISecretKey(user.api_secret_key)
        setTelegramUsername(user.telegram_username)
        setSymbolsArray(userSymbols)
    },[user]);

    return(
        <>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '85vh',
                    alignItems: "center",
                    // justifyContent: 'center',
                }}
            >
                <Box
                    maxWidth="80vw"
                    minWidth="60vw"
                >
                    <Paper />
                    <div style={{marginTop: "32px"}}>
                        <TextField
                            fullWidth
                            value={APIKey}
                            label="API Key"
                            onChange={handleAPIKey}
                        />
                    </div>
                    <div style={{marginTop: "16px"}}>
                        <TextField
                            fullWidth
                            value={APISecretKey}
                            label="API Secret Key"
                            onChange={handleAPISecretKey}
                        />
                    </div>
                    <div style={{marginTop: "16px"}}>
                        <TextField
                            fullWidth
                            value={telegramUsername}
                            label="Telegram link"
                            onChange={handleTelegramUsername}
                        />
                    </div>
                    <div style={{marginTop: "24px"}}>
                        <p style={{textAlign: "center"}}>Available symbols: {symbolsCountLeft}</p>
                    </div>
                    {userLicense.symbols_count === symbolsArray.length && <div style={{marginTop: "16px"}}><p style={{textAlign: "center"}}>Expand License</p></div>}
                    <div style={{marginTop: "16px"}}>
                        <UserProfileSelectSymbols symbolsArray={symbolsArray} setSymbolsArray={setSymbolsArray} />
                    </div>
                    <div style={{marginTop: "16px"}}>
                        <Button
                            variant="contained"
                            onClick={handleSave}
                        >Save</Button>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default UserProfileSettingsPage;