import React, {useEffect, useState, Dispatch, SetStateAction} from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box} from "@mui/system";
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {getAllAvaliableSymbols} from "../../../asyncActions/userSettings/getAllAvaliableSymbols";
import {useAppSelector} from "../../../hooks/redux";

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

export interface IUserProfileSelectSymbolsProps{
    symbolsArray: string[],
    setSymbolsArray: Dispatch<SetStateAction<string[]>>,
}

const UserProfileSelectSymbols: React.FC<IUserProfileSelectSymbolsProps> = ({symbolsArray, setSymbolsArray}) => {

    const [availableSybmolsArray, setAvailableSymbolsArray] = useState<string[]>([]);
    const userLicense = useAppSelector(state => state.userLicense)

    const handleDelete = (chipToDelete: string) => () => {
        setSymbolsArray((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleAddElement = (newSymbol: string | null) => {
        //Добавлять только в тех случаях, если такого элемента в массиве ещё нет
        if (newSymbol != null && (symbolsArray.indexOf(newSymbol) === -1)){
            setSymbolsArray([...symbolsArray, newSymbol])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await  getAllAvaliableSymbols();
            setAvailableSymbolsArray(data)
            return data;
        }
        fetchData()
    },[]);
    return(
        <>
            <Paper
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0.5,
                    m: 0,
                }}
                component="ul"
            >
                {symbolsArray.map((data) => {
                    let icon;
                    return (
                        <ListItem key={data}>
                            <Chip
                                icon={icon}
                                label={data}
                                onDelete={handleDelete(data)}
                            />
                        </ListItem>
                    );
                })}
            </Paper>
            <div style={{marginTop: "16px"}}>
                <Autocomplete
                    id="country-select-demo"
                    sx={{ width: 300 }}
                    options={availableSybmolsArray}
                    onChange={(event: any, newValue: string | null) => {
                        handleAddElement(newValue);
                    }}
                    autoHighlight
                    disabled={userLicense.symbols_count === symbolsArray.length}
                    getOptionLabel={(option: string) => option}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            {option}
                        </Box>
                    )}

                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose a symbol"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
        </>
    )
}

export default UserProfileSelectSymbols;