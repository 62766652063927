import React, {useEffect, useState, Dispatch, SetStateAction} from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box} from "@mui/system";
import Paper from '@mui/material/Paper';
import {getAllAvaliableSymbols} from "../../../../asyncActions/userSettings/getAllAvaliableSymbols";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {updateUserSymbols} from "../../../../asyncActions/userSettings/updateUserSymbols";



export interface IDashboardSelectSymbolProps{
    symbol: string | undefined,
    setSymbol: Dispatch<SetStateAction<string | undefined>>,
}

const DashboardSelectSymbol: React.FC<IDashboardSelectSymbolProps> = ({symbol, setSymbol}) => {
    const dispatch = useAppDispatch()
    // const userSymbols = useAppSelector(state => ["ALL", ...state.userSymbols.symbolsArray])
    const userSymbols = useAppSelector(state => state.userSymbols.symbolsArray)
    const userSymbolsWithAll = ["ALL", ...userSymbols]



    const handleAddElement = (newSymbol: string | null) => {
        if (newSymbol != null){
            setSymbol(newSymbol)
        }
    }


    return(
        <>
            <div style={{margin: "16px"}}>
                <div style={{marginTop: "16px"}}>
                    <Autocomplete
                        id="country-select-demo"
                        sx={{ width: 300 }}
                        options={userSymbolsWithAll}
                        onChange={(event: any, newValue: string | null) => {
                            handleAddElement(newValue);
                        }}
                        autoHighlight
                        getOptionLabel={(option: string) => option}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                {option}
                            </Box>
                        )}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={symbol === undefined ? "Choose a symbol" : symbol}

                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </>
    )
}

export default DashboardSelectSymbol;