import {siteOrigin, backendUrl} from "../../globalConfig";
import {AppDispatch} from "../../redux/store";
import {userDashboardSettingsSlice} from "../../redux/userDashboardSettingsReducer";


export const getUserDashboardSetting = () => async (dispatch: AppDispatch) => {
    fetch(backendUrl+'/dashboard/dashboard_user_element_position_get', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then((r) =>  {
            if(r.status===200){
                return r.json().then(data => ({status: r.status, json: data}))
            } else {
                let error = new Error(r.statusText);
                throw error
            }
        })
        .then((data) => {
            if (data.status===200){
                dispatch(userDashboardSettingsSlice.actions.updateUserDashboardSettingsArray(data.json))
            }
        })
        .catch((e) => {
            console.log('Error: ' + e.message);
        })
}