import React, {useEffect, Dispatch, SetStateAction} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {IOneDashboardCardProps} from "../../../../types/component_types";
import DashboardSelectSymbol from "../DashboardSelectSymbol/DashboardSelectSymbol";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {IOneDashboardElement} from "../../../../types/redux_types";
import DashboardSelectElement from "./DashboardSelectElement/DashboardSelectElement";
import {
    insertDashboardUserElementPosition
} from "../../../../asyncActions/dashboard/insertDashboardUserElementPosition";
import {
    deleteDashboardUserElementPosition
} from "../../../../asyncActions/dashboard/deleteDashboardUserElementPosition";


export interface IDashboardDialogProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    cardData: IOneDashboardCardProps
}

const DashboardCardDialog: React.FC<IDashboardDialogProps> = ({open, setOpen, cardData}) => {
    const dispatch = useAppDispatch()
    const dashboardElements = useAppSelector(state => state.dashboardElements.dataArray)
    const [symbol, setSymbol] = React.useState<string | undefined>(cardData.symbol);
    const [element, setElement] = React.useState<IOneDashboardElement | undefined>();
    const [daysCount, setDaysCount] = React.useState<number>(0);

    const handleClose = () => {
        setSymbol(undefined)
        setElement(undefined)
        setOpen(false);
    };

    const handleClear = () => {
        if(cardData !== undefined){
            if(cardData.positionId !== undefined){
                dispatch(deleteDashboardUserElementPosition(cardData.positionId))
            }
        }
        setSymbol(undefined)
        setElement(undefined)
        setOpen(false);
    };

    const handleUpdate = () => {
        if(symbol !== undefined && element !== undefined && cardData !== undefined){
            if(cardData.positionId !== undefined){
                dispatch(insertDashboardUserElementPosition(cardData.positionId, element.id, symbol, daysCount))
            }
        }
        setOpen(false);
    }

    const handleDaysCount = (e: React.ChangeEvent<any>) => {
        if (e.target.value !== undefined){setDaysCount(e.target.value)}
    }

    useEffect(() => {
        setElement(undefined)
    },[symbol])

    useEffect(() => {
        setSymbol(cardData.symbol)
        setElement(dashboardElements.find(el => el.id === cardData.elementId))
        if(cardData.days !== undefined){
            setDaysCount(cardData.days)
        }
    },[open])

    useEffect(() => {
        setSymbol(cardData.symbol)
        setElement(dashboardElements.find(el => el.id === cardData.elementId))
        if(cardData.days !== undefined){
            setDaysCount(cardData.days)
        }
    },[])

    useEffect(() => {
        if(cardData != undefined){
            if(cardData.days !== undefined){
                setDaysCount(cardData.days)
            }
        } else {
            setDaysCount(0)
        }

    },[element?.id])

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Symbol</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter what will displayed
                    </DialogContentText>

                    <DashboardSelectSymbol symbol={symbol} setSymbol={setSymbol} />

                    {symbol !== undefined && <DashboardSelectElement element={element} setElement={setElement} symbol={symbol}/>}

                    {element !== undefined && element.days_avaliable && <div style={{margin: "16px"}}>
                        <TextField
                            margin="dense"
                            id="days"
                            label="days count"
                            fullWidth
                            variant="standard"
                            type={"number"}
                            value={daysCount}
                            onChange={handleDaysCount}
                        />
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={handleClose}>Cancel</Button>
                    {cardData.elementId !== undefined && <Button color="inherit" onClick={handleClear}>Clear</Button>}
                    <Button
                        color="inherit"
                        onClick={handleUpdate}
                        disabled={element===undefined}
                    >Update</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DashboardCardDialog;

