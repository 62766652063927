

import {backendUrl} from "../../globalConfig";
import {AppDispatch} from "../../redux/store";
import {userDashboardSettingsSlice} from "../../redux/userDashboardSettingsReducer";

export const insertDashboardUserElementPosition = (positionId: number, elementId: number, symbol: string, days: number) => async (dispatch: AppDispatch) =>  {
    fetch(backendUrl+'/dashboard/dashboard_user_element_position_insert', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            position_id: positionId,
            element_id: elementId,
            symbol: symbol,
            days: days
        })
    })
        .then((r) =>  {
            if(r.status===200){
                return r.json().then(data => ({status: r.status, json: data}))
            } else {
                let error = new Error(r.statusText);
                throw error
            }
        })
        .then((data) => {
            if (data.status===200){
                dispatch(userDashboardSettingsSlice.actions.updateUserDashboardSettingsArray(data.json))
            }
        })
        .catch((e) => {
            console.log('Error: ' + e.message);
        })
}