import {siteOrigin, backendUrl} from "../../globalConfig";



export const getAllAvaliableSymbols = async () => {
    return await fetch(backendUrl+'/user/get_available_symbols', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then(r => r.json())
    // return resultArray
}