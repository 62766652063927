import React, {useEffect, useState} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import {getDashboardElements} from "../../asyncActions/dashboard/getDashboardElements";
import DashboardCard from "./DashboardCard/DashboardCard";
import {getUserSymbols} from "../../asyncActions/userSettings/getUserSymbols";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getUserDashboardSetting} from "../../asyncActions/dashboard/getUserDashboardSetting";

const DashboardPage: React.FC = () => {
    const dispatch = useAppDispatch()
    const dashboardElements = useAppSelector(state => state.dashboardElements.dataArray)
    const userDashboardSettings = useAppSelector(state => state.userDashboardSettings.dataArray)
    const positionsArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    // const [dashboardElementsArray, setDashboardElementsArray] = useState<IUserDashboardSetting[]>([]);


    useEffect(() => {
        // const fetchData = async () => {
        //     const data = await  getDashboardElements();
        //     setDashboardElementsArray(data)
        //     return data;
        // }
        // fetchData()
        dispatch(getUserSymbols())
        dispatch(getDashboardElements())
        dispatch(getUserDashboardSetting())
    },[]);

    return(
        <>
            <CssBaseline />
            <p>{dashboardElements.length}</p>
            <div style={{marginTop: "16px"}}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    flexGrow: 1
                }}
            >
                <Grid container spacing={{ xs: 2, md: 3 }}>
                    {positionsArray.map((element) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}  key={element}>
                            {userDashboardSettings.find(el => el.position_id === element) === undefined ?
                                <DashboardCard
                                    positionId={element}
                                /> :
                                <DashboardCard
                                    positionId={element}
                                    elementId={userDashboardSettings.find(el => el.position_id === element)?.element_id}
                                    symbol={userDashboardSettings.find(el => el.position_id === element)?.symbol}
                                    days={userDashboardSettings.find(el => el.position_id === element)?.days}
                                /> }
                        </Grid>
                    ))}
                </Grid>
            </Box>
            </div>
        </>
    )
}

export default DashboardPage;