import {backendUrl} from "../../globalConfig";

export const updateUserSettings = (APIKey: string, APISecretKey: string, telegramUsername: string) => {
    fetch(backendUrl+'/user/update_user_settings', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            api_key: APIKey,
            api_secret_key: APISecretKey,
            telegram_username: telegramUsername,
        })
    })
}
