import React, {useEffect} from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../hooks/redux";


const IndexPage: React.FC = () => {
    const navigate = useNavigate();
    const user = useAppSelector(state => state.user)

    useEffect(() => {
        if(user.email.length > 1){
            if(user.api_key.length > 1 && user.api_secret_key.length > 1){
                navigate("/dashboard")
            } else {
                navigate("/user_profile_settings")
            }
        }

    },[]);
    useEffect(() => {
        if(user.email.length > 1){
            if(user.api_key.length > 1 && user.api_secret_key.length > 1){
                navigate("/dashboard")
            } else {
                navigate("/user_profile_settings")
            }
        }

    },[user]);

    return(
        <>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                }}
            >
            </Box>
        </>
    )
}

export default IndexPage;