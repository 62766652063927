import * as React from 'react';
import {IOneDashboardCardProps} from "../../../types/component_types";
import DashboardCardEmpty from "./DashboardCardEmpty/DashboardCardEmpty";
import DashboardCardWithData from "./DashboardCardWithData/DashboardCardWithData";

const DashboardCard: React.FC<IOneDashboardCardProps> = (props) => {
    return (
        <>
            {props.elementId===undefined ? <DashboardCardEmpty positionId={props.positionId}/> :
                <DashboardCardWithData
                    positionId={props.positionId}
                    elementId={props.elementId}
                    symbol={props.symbol}
                    days={props.days}
                />}
        </>
    )
}

export default DashboardCard;